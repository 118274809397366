import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

import { media } from '../themes/index'

import { Title, Main, Content } from '../components/styles/pages'

import BlockContent from '@sanity/block-content-to-react'

import withSizes from 'react-sizes'

import get from 'lodash/get'
import styled from 'styled-components'

import Img from 'gatsby-image'
import withSanityImage from '../components/hoc/withSanityImage'

const ArticleTitle = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;

  ${media.tablet`
    font-size: 2rem;
    letter-spacing: 0.2rem;
  `}
`

const Author = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme.page.body.secondary_text_color};
  font-weight: 300;
  letter-spacing: 0.5rem;

  ${media.tablet`
    letter-spacing: 0.2rem;
  `}
`

const ArticleHeader = styled.header`
  margin-bottom: 2rem;
`

const ImageContainer = styled.div`
  margin-bottom: 2rem;
`

const ImageCaption = styled.p`
  padding-top: 0.3rem;
  font-size: 0.8rem;
`

const serializers = {
  types: {
    image: ({ node: { asset, caption } }) => {
      if (!asset) return null
      const SanityImage = withSanityImage(asset._ref)(Img)
      return (
        <ImageContainer>
          <SanityImage
            placeholderStyle={{ filter: 'blur(10px)' }}
            alt={caption}
          />
          {caption ? <ImageCaption>{caption}</ImageCaption> : null}
        </ImageContainer>
      )
    },
  },
}

const PaperPageTemplate = ({
  data,
  pageContext,
  windowWidth,
  windowHeight,
}) => {
  const { title, author } = pageContext
  const _rawBody = get(data, 'sanityPaper._rawBody')

  return (
    <Layout>
      <Main>
        <Title>Scritti</Title>
        <Content as="article" size="small">
          <ArticleHeader>
            <ArticleTitle>{title}</ArticleTitle>
            {author && <Author>{author}</Author>}
          </ArticleHeader>
          {_rawBody && (
            <BlockContent
              blocks={_rawBody}
              serializers={serializers}
              // imageOptions={{ w: 320, h: 240, fit: 'max' }}
              // projectId="fa60ke5o"
              // dataset="production"
            />
          )}
        </Content>
      </Main>
    </Layout>
  )
}

const mapSizesToProps = ({ width, height }) => {
  return { windowWidth: width, windowHeight: height }
}

export default withSizes(mapSizesToProps)(PaperPageTemplate)

export const query = graphql`
  query($id: String!) {
    sanityPaper(id: { eq: $id }) {
      id
      _rawBody
    }
  }
`
